import React, { useEffect, useState } from "react";
import Logo from "../../../assets/img/empty-img-logo.png";
import BannerSampleImg from "../../../assets/img/empty-banner.png"; //
import { LazyLoadImage } from "react-lazy-load-image-component";
import Lottie from "lottie-react";
import animation from "../../../assets/loading_animation.json";
// Config Data
import config from "../../../config.json";
const { imageDisplayUrl } = config;

export default function Image({
  name,
  direct,
  banner,
  alt = "image",
  ...rest
}) {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = (e) => {
    setIsLoaded(true);
  };

  // const handleImageError = (e) => {
  //   setIsLoaded(false);
  // };

  return (
    <>
      <LazyLoadImage
        src={
          !isLoaded
            ? Logo
            : direct
            ? name || imageDisplayUrl + name
            : imageDisplayUrl + name
        }
        onLoad={handleImageLoad}
        // onError={handleImageError}
        alt={alt}
        effect="blur"
        {...rest}
      />
    </>
  );
}
