import React, { useContext, useState, useEffect } from "react";
import "./t&cpage.css";
import "../style/landing.css";

import { Link, useNavigate } from "react-router-dom";
import OffCanvas from "react-aria-offcanvas";
import CommonContext from "../../context/CommonContext";
import { getToken } from "../auth/authService";
import {
  getLocalStorageCurrentUserName,
  getLocalStorageUserLocation,
} from "../../services/localStorage";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import { InlineIcon } from "@iconify/react";
import userOutlineIcon from "@iconify/icons-mdi/user-outline";
import usersOutlineIcon from "@iconify/icons-mdi/users-outline";
import facebookIcon from "@iconify/icons-mdi/facebook";
import googleIcon from "@iconify/icons-mdi/google";
import instagramIcon from "@iconify/icons-mdi/instagram";
import twitterIcon from "@iconify/icons-mdi/twitter";

// images
import Logo from "../../assets/img/yoyo_logo.png";
import menuLogo from "../../assets/img/yoyo_logo_white.png";

const offCanvasMenuStyles = {
  content: {
    background: "rgb(255,255,255)",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.25)",
    zIndex: "99999",
  },
  menuButton: {
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    fontSize: "23px",
    padding: 0,
    marginTop: -4,
  },
  icon: {
    color: "red",
  },
  menuBtnIcon: {
    color: "#fff",
    fontSize: 18,
  },
  menuButtonClose: {
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    color: "#676767",
    fontWeight: "400",
    position: "absolute",
    top: 3,
    right: 0,
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    padding: 12,
    fontSize: 18,
  },
  OffCanvasMenuList: {
    listStyle: "none",
    paddingLeft: 0,
  },
};
//Images and Icons
// import { Icon } from "@iconify/react";
// import LogoImage from "../../assets/AxtraLogoLong.png";
export default function TermsAndConditionsPage() {
  const commonContext = useContext(CommonContext);
  const { languageData } = commonContext;
  let token = getToken();
  const navigate = useNavigate();
  const userName = getLocalStorageCurrentUserName();
  const [isOpen, setIsOpen] = useState(false);
  const [stickyMenu, setStickyMenu] = useState(false);

  const close = () => {
    setIsOpen(false);
  };

  return (
    <div className="landing-wrap">
      <header class="main-menu-area sticky-menu">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-3 col-sm-3 col-xs-6">
              <div className="navbar-brand d-flex">
                <button
                  aria-label="Menu"
                  aria-controls="menu"
                  aria-expanded={isOpen}
                  onClick={() => setIsOpen(true)}
                  style={offCanvasMenuStyles.menuButton}
                  className="d-block d-lg-none d-md-none d-sm-block"
                >
                  <FontAwesomeIcon icon={faBars} className="menu-icon" />
                </button>

                <Link to="/">
                  <img src={Logo} className="logo-img" alt="Logo Image" />
                </Link>
              </div>
            </div>

            <div class="col-md-9 col-sm-9 col-xs-6 d-none d-xl-block d-lg-block d-md-block d-sm-none">
              <nav class="landing-page-menu">
                <ul className="mb-0">
                  <li>
                    {token ? (
                      <Link to="/userProfile">
                        <span>{userName}</span>
                      </Link>
                    ) : (
                      <Link to="/login">
                        <span>{languageData.loginOrSingUp}</span>
                      </Link>
                    )}
                  </li>
                  <li>
                    <Link
                      className="nav-link partner-button"
                      to="https://www.12zay.com/InstaPOSAdmin/registerShop"
                      target="_blank"
                    >
                      <span>{languageData.partnerWithUs}</span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <section className="py-lg-4 pt-sm-0 header-offset-top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
              <h1 className="section-main-title mt-4">Terms and Conditions</h1>
              <p className="content-text">Last Updated: [Date]</p>
              <hr />
              <p className="content-text mb-4">
                Welcome to{" "}
                <b>Axtra Solution Co., Ltd's Axtra POS Merchant application</b>.
                By accessing or using this POS application, you agree to be
                bound by the following terms and conditions. Please read these
                Terms carefully before using the Service.
              </p>
              <div className="para-block">
                <p className="para-title">1. Acceptance of Terms</p>
                <p className="content-text">
                  By using the Service, you confirm that you have read,
                  understood, and agree to be bound by these Terms and any
                  future modifications. If you do not agree to these Terms, you
                  may not access or use the Service.
                </p>
              </div>
              <div className="para-block">
                <p className="para-title">2. Use of the Service</p>
                <p className="para-title">2.1 License</p>
                <p className="content-text mb-3">
                  Axtra Solution Co., Ltd grants you a non-exclusive,
                  non-transferable, limited license to use the Service for your
                  business operations in accordance with these Terms.
                </p>
                <p className="para-title">2.2 Restrictions</p>
                <p className="content-text">You agree not to:</p>
                <ul className="mb-3">
                  <li className="list-item">
                    <p className="content-text">
                      Modify,adapt, or hack the Service.
                    </p>
                  </li>
                  <li className="list-item">
                    <p className="content-text">
                      Reverse engineer, decompile, disassemble, or attempt to
                      discover the source code of the Service.
                    </p>
                  </li>
                  <li className="list-item">
                    <p className="content-text">
                      Reproduce, duplicate, copy, sell, resell, or exploit any
                      portion of the Service without express written permission
                      from Axtra Solution Co., Ltd.
                    </p>
                  </li>
                  <li className="list-item">
                    <p className="content-text">
                      Use the Service in any unlawful manner or in any manner
                      that interferes with or disrupts the integrity or
                      performance of the Service and its components.
                    </p>
                  </li>
                </ul>
                <p className="para-title">2.3 Compliance with Laws</p>
                <p className="content-text">
                  You are responsible for complying with all applicable laws and
                  regulations related to your use of the Service, including but
                  not limited to privacy, data protection, and consumer
                  protection laws.
                </p>
              </div>
              <div className="para-block">
                <p className="para-title">3. Account Registration</p>
                <p className="para-title">3.1 Registration Information</p>
                <p className="content-text mb-3">
                  To use the Service, you must register and create an account.
                  You agree to provide accurate, current, and complete
                  information during the registration process and to update such
                  information to keep it accurate, current, and complete.
                </p>
                <p className="para-title">3.2 Account Security</p>
                <p className="content-text">
                  You are responsible for maintaining the confidentiality of
                  your account credentials and for all activities that occur
                  under your account. You agree to notify{" "}
                  <b>Axtra Solution Co., Ltd</b> immediately of any unauthorized
                  use of your account or any other breach of security.
                </p>
              </div>
              <div className="para-block">
                <p className="para-title">4. Payment and Fees</p>
                <p className="para-title">4.1 Payment Terms</p>
                <p className="content-text mb-3">
                  Fees for the Service are charged on a [monthly/annual] basis
                  as agreed upon at the time of subscription. You agree to pay
                  all fees and applicable taxes incurred by your account in
                  accordance with the payment terms.
                </p>
                <p className="para-title">4.2 Changes in Fees</p>
                <p className="content-text mb-3">
                  <b> Axtra Solution Co., Ltd</b> reserves the right to change
                  the fees for the Service at any time with prior notice. Any
                  changes in fees will be effective upon renewal of your
                  subscription.
                </p>
                <p className="para-title">4.3 Payment Processing Fees</p>
                <p className="content-text mb-0">
                  When you accept payments from customers using our application,
                  please note that fees will be incurred for processing payments
                  through certain methods like electronics payments. By using
                  our application to process payments, you acknowledge and agree
                  to these fees.
                </p>
              </div>
              <div className="para-block">
                <p className="para-title">5. Data and Privacy</p>
                <p className="para-title">5.1 Data Ownership</p>
                <p className="content-text mb-3">
                  You retain ownership of all data you input into the Service.
                  By using the Service, you grant <b>Axtra Solution Co., Ltd</b>{" "}
                  a non-exclusive, worldwide, royalty-free license to use,
                  store, and process your data to provide and improve the
                  Service.
                </p>
                <p className="para-title">5.2 Data Security</p>
                <p className="content-text mb-3">
                  <b>Axtra Solution Co., Ltd</b> implements industry-standard
                  security measures to protect your data. However, you
                  acknowledge that no method of transmission over the Internet,
                  or method of electronic storage, is 100% secure, and{" "}
                  <b>Axtra Solution Co., Ltd</b> cannot guarantee absolute
                  security.
                </p>
                <p className="para-title">5.3 Privacy Policy</p>
                <p className="content-text mb-0">
                  Your use of the Service is also governed by our Privacy
                  Policy, which can be found at [link to Privacy Policy]. By
                  using the Service, you agree to the terms of the Privacy
                  Policy.
                </p>
              </div>

              <div className="para-block">
                <p className="para-title">6. Termination</p>
                <p className="para-title">6.1 Termination by You</p>
                <p className="content-text mb-3">
                  You may terminate your account at any time by providing notice
                  to <b>Axtra Solution Co., Ltd</b>. Upon termination, you will
                  cease all use of the Service and{" "}
                  <b>Axtra Solution Co., Ltd</b> will delete your account.
                </p>
                <p className="para-title">
                  6.2 Termination by Axtra Solution Co., Ltd
                </p>
                <p className="content-text mb-3">
                  <b>Axtra Solution Co., Ltd</b> may suspend or terminate your
                  access to the Service at any time, with or without cause, and
                  without notice.
                </p>
                <p className="para-title">6.3 Effect of Termination</p>
                <p className="content-text mb-0">
                  Upon termination, all licenses granted to you under these
                  Terms will immediately cease. Any outstanding payments owed by
                  you will remain due.
                </p>
              </div>

              <div className="para-block">
                <p className="para-title">7. Limitation of Liability</p>
                <p className="content-text">
                  To the fullest extent permitted by law,
                  <b>Axtra Solution Co., Ltd</b> shall not be liable for any
                  indirect, incidental, special, consequential, or punitive
                  damages, or any loss of profits or revenues, whether incurred
                  directly or indirectly, or any loss of data, use, goodwill, or
                  other intangible losses, resulting from your use of the
                  Service.
                </p>
              </div>

              <div className="para-block">
                <p className="para-title">8. Indemnification</p>
                <p className="content-text">
                  You agree to indemnify and hold harmless{" "}
                  <b>Axtra Solution Co., Ltd</b> and its affiliates, officers,
                  directors, employees, and agents from any claims, liabilities,
                  damages, losses, and expenses, including reasonable attorneys'
                  fees, arising out of or in any way connected with your use of
                  the Service.
                </p>
              </div>

              <div className="para-block">
                <p className="para-title">9. Modifications to the Service</p>
                <p className="content-text">
                  <b>Axtra Solution Co., Ltd</b> reserves the right to modify,
                  suspend, or discontinue the Service at any time, with or
                  without notice. You agree that [Your Company Name] will not be
                  liable to you or to any third party for any modification,
                  suspension, or discontinuation of the Service.
                </p>
              </div>

              <div className="para-block">
                <p className="para-title">10. Governing Law</p>
                <p className="content-text">
                  These Terms shall be governed by and construed in accordance
                  with the laws of Myanmar, without regard to its conflict of
                  law principles.
                </p>
              </div>

              <div className="para-block">
                <p className="para-title">11. Dispute Resolution</p>
                <p className="content-text">
                  Any disputes arising out of or in connection with these Terms
                  shall be resolved through binding arbitration in accordance
                  with the rules of Arbitration Association, and the decision of
                  the arbitrator(s) shall be final and binding.
                </p>
              </div>

              <div className="para-block">
                <p className="para-title">12. Severability</p>
                <p className="content-text">
                  If any provision of these Terms is found to be invalid or
                  unenforceable by a court of competent jurisdiction, the
                  remaining provisions will continue in full force and effect.
                </p>
              </div>

              <div className="para-block">
                <p className="para-title">13. Entire Agreement</p>
                <p className="content-text">
                  These Terms, together with any applicable Privacy Policy and
                  other legal notices, constitute the entire agreement between
                  you and Axtra Solution Co., Ltd concerning the Service.
                </p>
              </div>

              <div className="para-block">
                <p className="para-title">14. Contact Information</p>
                <p className="content-text">
                  If you have any questions about these Terms, please contact us
                  at “info@axtrapos.com”.
                </p>
              </div>
            </div>
            {/* col ends  */}
          </div>
          {/* row ends  */}
        </div>
        {/* container ends  */}
      </section>
      {/* Footer Section */}
      <div className="footer">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
              <div className="footer-link-wrap d-none d-lg-block d-md-block mb-3">
                <Link className="footer-link" to="/termsandconditions">
                  <span>Terms and Conditions</span>
                </Link>
                <Link className="footer-link" to="/privacyPolicy">
                  <span>Privacy Policy</span>
                </Link>
                <Link className="footer-link" to="/">
                  <span>FAQs</span>
                </Link>
                <Link className="footer-link" to="/">
                  <span>About Us</span>
                </Link>
                <Link className="footer-link" to="/">
                  <span>Contact</span>
                </Link>
              </div>
              <div className="footer-link-wrap d-block d-lg-none d-md-none d-flex flex-column align-items-center mb-3">
                <Link className="footer-link" to="/termsandconditions">
                  <span>Terms and Conditions</span>
                </Link>
                <Link className="footer-link" to="/privacyPolicy">
                  <span>Privacy Policy</span>
                </Link>
                <Link className="footer-link" to="/">
                  <span>FAQs</span>
                </Link>
                <Link className="footer-link" to="/">
                  <span>About Us</span>
                </Link>
                <Link className="footer-link" to="/">
                  <span>Contact</span>
                </Link>
              </div>
              <div className="d-flex flex-row mb-3">
                <Link to={"/home"} className="social-img-wrap">
                  <InlineIcon icon={facebookIcon} />
                </Link>
                <Link to={"/home"} className="social-img-wrap">
                  <InlineIcon icon={googleIcon} />
                </Link>
                <Link to={"/home"} className="social-img-wrap">
                  <InlineIcon icon={instagramIcon} />
                </Link>
                <Link to={"/home"} className="social-img-wrap">
                  <InlineIcon icon={twitterIcon} />
                </Link>
              </div>
              <p className="copyrights">© 2024 Axtra, All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
      <OffCanvas
        isOpen={isOpen}
        onClose={close}
        labelledby="menu-button"
        height="100%"
        position="left"
        style={offCanvasMenuStyles}
      >
        <div className="menu-header" onClick={close}>
          <Link to={token ? "/home" : "/"} className="menu-header-logo">
            <img src={menuLogo} className="menu-logo" alt="Logo Image" />
          </Link>
        </div>

        <nav id="menu">
          <ul style={offCanvasMenuStyles.OffCanvasMenuList}>
            <li>
              <Link
                to="/login"
                className="offcanvas-menu_link d-flex flex-row"
                onClick={close}
              >
                <InlineIcon
                  icon={userOutlineIcon}
                  className="user-outline-icon mx-2 me-3"
                />
                <span>{languageData.loginOrSingUp}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/home"
                className="offcanvas-menu_link d-flex flex-row"
                onClick={close}
              >
                <InlineIcon
                  icon={usersOutlineIcon}
                  className="icon mx-1 me-3"
                />
                <span>{languageData.partnerWithUs}</span>
              </Link>
            </li>
          </ul>
        </nav>
      </OffCanvas>
    </div>
  );
}
