import React, { useContext, useState, useEffect } from "react";
import "./t&cpage.css";
import "../style/landing.css";

import { Link, useNavigate } from "react-router-dom";
import OffCanvas from "react-aria-offcanvas";
import CommonContext from "../../context/CommonContext";
import { getToken } from "../auth/authService";
import {
  getLocalStorageCurrentUserName,
  getLocalStorageUserLocation,
} from "../../services/localStorage";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import { InlineIcon } from "@iconify/react";
import userOutlineIcon from "@iconify/icons-mdi/user-outline";
import usersOutlineIcon from "@iconify/icons-mdi/users-outline";
import facebookIcon from "@iconify/icons-mdi/facebook";
import googleIcon from "@iconify/icons-mdi/google";
import instagramIcon from "@iconify/icons-mdi/instagram";
import twitterIcon from "@iconify/icons-mdi/twitter";

// images
import Logo from "../../assets/img/yoyo_logo.png";
import menuLogo from "../../assets/img/yoyo_logo_white.png";

const offCanvasMenuStyles = {
  content: {
    background: "rgb(255,255,255)",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.25)",
    zIndex: "99999",
  },
  menuButton: {
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    fontSize: "23px",
    padding: 0,
    marginTop: -4,
  },
  icon: {
    color: "red",
  },
  menuBtnIcon: {
    color: "#fff",
    fontSize: 18,
  },
  menuButtonClose: {
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    color: "#676767",
    fontWeight: "400",
    position: "absolute",
    top: 3,
    right: 0,
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    padding: 12,
    fontSize: 18,
  },
  OffCanvasMenuList: {
    listStyle: "none",
    paddingLeft: 0,
  },
};
//Images and Icons
// import { Icon } from "@iconify/react";
// import LogoImage from "../../assets/AxtraLogoLong.png";
export default function PrivacyPolicyPage() {
  const commonContext = useContext(CommonContext);
  const { languageData } = commonContext;
  let token = getToken();
  const navigate = useNavigate();
  const userName = getLocalStorageCurrentUserName();
  const [isOpen, setIsOpen] = useState(false);
  const [stickyMenu, setStickyMenu] = useState(false);

  const close = () => {
    setIsOpen(false);
  };

  return (
    <div className="landing-wrap">
      <header class="main-menu-area sticky-menu">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-3 col-sm-3 col-xs-6">
              <div className="navbar-brand d-flex">
                <button
                  aria-label="Menu"
                  aria-controls="menu"
                  aria-expanded={isOpen}
                  onClick={() => setIsOpen(true)}
                  style={offCanvasMenuStyles.menuButton}
                  className="d-block d-lg-none d-md-none d-sm-block"
                >
                  <FontAwesomeIcon icon={faBars} className="menu-icon" />
                </button>

                <Link to="/">
                  <img src={Logo} className="logo-img" alt="Logo Image" />
                </Link>
              </div>
            </div>

            <div class="col-md-9 col-sm-9 col-xs-6 d-none d-xl-block d-lg-block d-md-block d-sm-none">
              <nav class="landing-page-menu">
                <ul className="mb-0">
                  <li>
                    {token ? (
                      <Link to="/userProfile">
                        <span>{userName}</span>
                      </Link>
                    ) : (
                      <Link to="/login">
                        <span>{languageData.loginOrSingUp}</span>
                      </Link>
                    )}
                  </li>
                  <li>
                    <Link
                      className="nav-link partner-button"
                      to="https://www.12zay.com/InstaPOSAdmin/registerShop"
                      target="_blank"
                    >
                      <span>{languageData.partnerWithUs}</span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <section className="py-lg-4 pt-sm-0 header-offset-top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
              <h1 className="section-main-title mt-4">Privacy Policy</h1>
              <p className="content-text">Effective Date: [Date]</p>
              <hr />
              <div className="para-block">
                <p className="para-title">1. Introduction</p>
                <p className="content-text">
                  Welcome to <b>Axtra POS Merchant</b>. We are committed to
                  protecting your privacy and ensuring that your personal
                  information is handled securely and responsibly. This Privacy
                  Policy explains how we collect, use, and protect your
                  information when you use our <b>Axtra POS Merchant</b>.
                </p>
              </div>
              <div className="para-block">
                <p className="para-title">2. Information We Collect</p>
                <p className="content-text">
                  We collect the following types of information:
                </p>
                <ul className="mb-3">
                  <li className="list-item">
                    <p className="content-text">
                      <b>Personal Information :</b> This includes your name,
                      email address, phone number, and other contact details
                      that you provide when you sign up or use our application.
                    </p>
                  </li>
                  <li className="list-item">
                    <p className="content-text">
                      <b>Transaction Data :</b> Details of transactions made
                      through the POS system, including items purchased, payment
                      methods, and transaction amounts.
                    </p>
                  </li>
                  <li className="list-item">
                    <p className="content-text">
                      <b>Device Information :</b> Information about the device
                      you use to access our POS application, including IP
                      address, operating system, and browser type.
                    </p>
                  </li>
                  <li className="list-item">
                    <p className="content-text">
                      <b>Usage Data :</b> Data on how you interact with our
                      application, such as pages visited, features used, and
                      actions taken.
                    </p>
                  </li>
                </ul>
              </div>

              <div className="para-block">
                <p className="para-title">3. How We Use Your Information</p>
                <p className="content-text">
                  We use the information we collect for the following purposes:
                </p>
                <ul className="mb-3">
                  <li className="list-item">
                    <p className="content-text">
                      <b>To Provide and Improve Our Services :</b> We use your
                      information to operate, maintain, and improve our POS
                      application.
                    </p>
                  </li>
                  <li className="list-item">
                    <p className="content-text">
                      <b>To Process Transactions :</b> Your transaction data is
                      used to process payments and manage your sales records.
                    </p>
                  </li>
                  <li className="list-item">
                    <p className="content-text">
                      <b>To Communicate With You :</b> We may use your contact
                      information to send you updates, notifications, and other
                      important information related to your use of our
                      application.
                    </p>
                  </li>
                  <li className="list-item">
                    <p className="content-text">
                      <b>To Enhance Security :</b> Device and usage data help us
                      detect and prevent fraudulent activities and enhance the
                      security of our application.
                    </p>
                  </li>
                  <li className="list-item">
                    <p className="content-text">
                      <b>For Compliance :</b> We may use your data to comply
                      with legal obligations and enforce our terms of service.
                    </p>
                  </li>
                </ul>
              </div>

              <div className="para-block">
                <p className="para-title">4. Data Sharing and Disclosure</p>
                <p className="content-text">
                  We do not sell, trade, or otherwise transfer your personal
                  information to third parties, except in the following
                  circumstances:
                </p>
                <ul className="mb-3">
                  <li className="list-item">
                    <p className="content-text">
                      <b>Service Providers :</b> We may share your information
                      with third-party service providers who assist us in
                      operating our application, conducting our business, or
                      providing services to you, under strict confidentiality
                      agreements.
                    </p>
                  </li>
                  <li className="list-item">
                    <p className="content-text">
                      <b>Legal Requirements :</b> We may disclose your
                      information if required to do so by law or in response to
                      valid requests by public authorities.
                    </p>
                  </li>
                </ul>
              </div>

              <div className="para-block">
                <p className="para-title">5. Data Security</p>
                <p className="content-text">
                  We implement a variety of security measures to ensure the
                  safety of your personal information. These include encryption,
                  secure access controls, and regular security audits. However,
                  no method of transmission over the Internet or electronic
                  storage is 100% secure, and we cannot guarantee absolute
                  security.
                </p>
              </div>

              <div className="para-block">
                <p className="para-title">6. Data Retention</p>
                <p className="content-text">
                  We retain your personal information only for as long as
                  necessary to fulfill the purposes outlined in this policy, or
                  as required by law.
                </p>
              </div>

              <div className="para-block">
                <p className="para-title">7. Your Rights</p>
                <p className="content-text">You have the right to:</p>
                <ul className="mb-2">
                  <li className="list-item">
                    <p className="content-text">
                      Access and review the information we hold about you.
                    </p>
                  </li>
                  <li className="list-item">
                    <p className="content-text">
                      Request correction or deletion of your personal
                      information.
                    </p>
                  </li>
                  <li className="list-item">
                    <p className="content-text">
                      Withdraw your consent to our use of your information,
                      where applicable.
                    </p>
                  </li>
                </ul>
                <p className="content-text">
                  To exercise these rights, please contact us at
                  “info@axtrapos.com”.
                </p>
              </div>

              <div className="para-block">
                <p className="para-title">8. Changes to This Privacy Policy</p>
                <p className="content-text">
                  We may update this Privacy Policy from time to time. Any
                  changes will be posted on this page with an updated effective
                  date. We encourage you to review this policy periodically.
                </p>
              </div>

              <div className="para-block">
                <p className="para-title">9. Contact Us</p>
                <p className="content-text">
                  If you have any questions about this Privacy Policy or our
                  practices, please contact us at: <br />
                  <b>
                    Axtra Pos Solution 10B, Pansoedan Business Tower, Pansoedan
                    Road & Anawyahtar Road, Kyakutada Township.
                  </b>{" "}
                  <br />
                  <b>info@axtrapos.com</b>
                </p>
              </div>
            </div>
            {/* col ends  */}
          </div>
          {/* row ends  */}
        </div>
        {/* container ends  */}
      </section>
      {/* Footer Section */}
      <div className="footer">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
              <div className="footer-link-wrap d-none d-lg-block d-md-block mb-3">
                <Link className="footer-link" to="/termsandconditions">
                  <span>Terms and Conditions</span>
                </Link>
                <Link className="footer-link" to="/privacyPolicy">
                  <span>Privacy Policy</span>
                </Link>
                <Link className="footer-link" to="/">
                  <span>FAQs</span>
                </Link>
                <Link className="footer-link" to="/">
                  <span>About Us</span>
                </Link>
                <Link className="footer-link" to="/">
                  <span>Contact</span>
                </Link>
              </div>
              <div className="footer-link-wrap d-block d-lg-none d-md-none d-flex flex-column align-items-center mb-3">
                <Link className="footer-link" to="/termsandconditions">
                  <span>Terms and Conditions</span>
                </Link>
                <Link className="footer-link" to="/privacyPolicy">
                  <span>Privacy Policy</span>
                </Link>
                <Link className="footer-link" to="/">
                  <span>FAQs</span>
                </Link>
                <Link className="footer-link" to="/">
                  <span>About Us</span>
                </Link>
                <Link className="footer-link" to="/">
                  <span>Contact</span>
                </Link>
              </div>
              <div className="d-flex flex-row mb-3">
                <Link to={"/home"} className="social-img-wrap">
                  <InlineIcon icon={facebookIcon} />
                </Link>
                <Link to={"/home"} className="social-img-wrap">
                  <InlineIcon icon={googleIcon} />
                </Link>
                <Link to={"/home"} className="social-img-wrap">
                  <InlineIcon icon={instagramIcon} />
                </Link>
                <Link to={"/home"} className="social-img-wrap">
                  <InlineIcon icon={twitterIcon} />
                </Link>
              </div>
              <p className="copyrights">© 2024 Axtra, All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
      <OffCanvas
        isOpen={isOpen}
        onClose={close}
        labelledby="menu-button"
        height="100%"
        position="left"
        style={offCanvasMenuStyles}
      >
        <div className="menu-header" onClick={close}>
          <Link to={token ? "/home" : "/"} className="menu-header-logo">
            <img src={menuLogo} className="menu-logo" alt="Logo Image" />
          </Link>
        </div>

        <nav id="menu">
          <ul style={offCanvasMenuStyles.OffCanvasMenuList}>
            <li>
              <Link
                to="/login"
                className="offcanvas-menu_link d-flex flex-row"
                onClick={close}
              >
                <InlineIcon
                  icon={userOutlineIcon}
                  className="user-outline-icon mx-2 me-3"
                />
                <span>{languageData.loginOrSingUp}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/home"
                className="offcanvas-menu_link d-flex flex-row"
                onClick={close}
              >
                <InlineIcon
                  icon={usersOutlineIcon}
                  className="icon mx-1 me-3"
                />
                <span>{languageData.partnerWithUs}</span>
              </Link>
            </li>
          </ul>
        </nav>
      </OffCanvas>
    </div>
  );
}
