import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { InlineIcon } from "@iconify/react";
import closeIcon from "@iconify/icons-mdi/close";
import CommonContext from "../../context/CommonContext";
import CartContext from "../../context/CartContext";
/// Components
import { Image, Button, Textarea } from "../common";
/// Utils
import { numberWithCommas } from "../../utils/number";
// styles
import "../style/product-detail.css";
import "../style/product-list.css";
/// Config Data
import config from "../../config.json";
import _ from "lodash";
import { discountPercentOff } from "./helper";

const { CURRENCY_CODE } = config;

export default function CartProductEditModal({
  isShow = false,
  setIsShow,
  data,
  isPos = false,
  shopTypes,
  changeCartData,
  shopId,
  cartData,
  setCartData,
}) {
  const commonContext = useContext(CommonContext);
  const { languageData } = commonContext;

  const cartContext = useContext(CartContext);
  const { cart } = cartContext;

  const [product, setProduct] = useState(data);
  const [customerNote, setCustomerNote] = useState("");
  const [isLoadDoSubmit, setIsLoadDoSubmit] = useState(false);
  const [price, setPrice] = useState(0);
  const [salePrice, setSalePrice] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [isOutOfStock, setIsOutOfStock] = useState(false);

  useEffect(() => {
    (async () => {
      if (isShow) {
        loadProductData();
        setCustomerNote(data.customerNote || "");
      }
    })();
  }, [data, isShow]);

  const toggle = () => {
    if (isShow) {
      setProduct({});
    }
    setIsShow(!isShow);
  };

  const loadProductData = async () => {
    try {
      setIsLoad(true);
      let tProduct = await _.cloneDeep(data);
      let price = tProduct.productData.price;
      let salePrice = tProduct.productData.salePrice;
      setMaxQuantity(tProduct.productData.maxQuantity);
      if (tProduct.attributes) {
        if (shopTypes.isShop) {
          let selectedAttributeOptions = [];

          tProduct.attributes.map((att) => {
            let attId = att.id;
            att.options?.map((opt) => {
              opt.isSelected = opt.isChecked;
            });

            let attributeOption = {
              attributeId: attId,
              attributeOptionId: att.options.find((o) => o.isSelected === true)
                .attributeOptionId,
            };
            selectedAttributeOptions.push(attributeOption);
          });

          tProduct.productData.attributeCombinations.map((row) => {
            row.sortedXml = JSON.parse(row.attributesXml).sort(
              (a, b) => a.attributeId - b.attributeId
            );
          });

          const sortSelectedOptions = selectedAttributeOptions.sort(
            (a, b) => a.attributeId - b.attributeId
          );

          const findCombineRow =
            await tProduct.productData.attributeCombinations.find(
              (row) =>
                JSON.stringify(row.sortedXml) ===
                JSON.stringify(sortSelectedOptions)
            );

          if (findCombineRow) {
            price += findCombineRow.priceAdjustment;
            salePrice += findCombineRow.priceAdjustment;

            setMaxQuantity(findCombineRow.maxQuantity);
            setIsOutOfStock(findCombineRow.isOutOfStock);
          }
        } else {
          for (let attribute of tProduct.attributes) {
            let findSelectedAtt = tProduct.selectedAttributes.find(
              (a) => a.id == attribute.id
            );
            if (findSelectedAtt) {
              for (let opt of attribute.options) {
                opt.isSelected = false;
                let findOpt = findSelectedAtt.options.find(
                  (item) => item.attributeOptionId == opt.attributeOptionId
                );
                if (findOpt) {
                  opt.isSelected = true;
                  price += opt.price;
                }
              }
            }
          }
        }
      }
      setPrice(price);
      setSalePrice(salePrice);
      setProduct(tProduct);

      setIsLoad(false);
    } catch (error) {
      console.log("loadProductData error ", error);
    }
  };

  const handleSelectAttribute = async (attributeIdx, optionId) => {
    try {
      let tProduct = await _.cloneDeep(product);
      let price = tProduct.productData.price;
      await Promise.all(
        tProduct.attributes.map(async (item, attIdx) => {
          if (attIdx === attributeIdx) {
            if (item.type === 1) {
              await item.options.map((opt, optIdx) => {
                opt.isSelected = false;
                if (opt.attributeOptionId === optionId) {
                  opt.isSelected = true;
                  price += opt.price;
                }
              });
            } else if (item.type === 2) {
              let findCurrentOpt = await item.options.find(
                (opt) => opt.attributeOptionId == optionId
              );
              if (findCurrentOpt) {
                if (findCurrentOpt.isSelected == false) {
                  findCurrentOpt.isSelected = true;
                  price += findCurrentOpt.price;
                } else {
                  let findOtherOpt = await item.options.find(
                    (opt) =>
                      opt.attributeOptionId !== optionId &&
                      opt.isSelected == true
                  );
                  if (findOtherOpt) {
                    findCurrentOpt.isSelected = false;
                  }
                }
              }
            } else {
              await item.options.map((opt) => {
                if (opt.attributeOptionId === optionId) {
                  opt.isSelected = !opt.isSelected;
                  if (opt.isSelected) {
                    price += opt.price;
                  }
                }
              });
            }
          }
        })
      ).then(async () => {
        setProduct(tProduct);
        setPrice(price);
        if (tProduct.productData.discountBenefitAmount > 0) {
          let discountPrice = await discountPercentOff(
            price,
            tProduct.productData.discountBenefitAmount
          );
          setSalePrice(discountPrice);
        }
      });
    } catch (error) {
      NotificationManager.error(
        "product detail selected attribute failed ",
        languageData.error
      );
    }
  };

  const onClickSubComboProduct = async (id) => {
    try {
      let tProduct = _.cloneDeep(product.productData);
      let tComboProducts = _.cloneDeep(tProduct.comboProducts);
      let cusSelectedQty = 0;
      tComboProducts.map((c) => {
        if (c.isSelected) {
          if (c.id !== id) {
            cusSelectedQty += 1;
          }
        }
      });

      product.error = null;
      if (cusSelectedQty < tProduct.maxProduct) {
        tComboProducts.map((c) => {
          if (!c.isDefault) {
            if (c.id === id) {
              c.isSelected = !c.isSelected;
            }
          }
        });
      } else {
        product.error = languageData.comboSetLimitError;
      }
      tProduct.comboProducts = tComboProducts;

      setProduct({ ...product, productData: tProduct });
    } catch (error) {
      console.log(error);
    }
  };

  const doSubmit = async () => {
    try {
      setIsLoadDoSubmit(true);
      /// Add To Cart to local Storage
      const localCartData = _.cloneDeep(cartData);
      console.log("LOCAL CART DATA IS ", localCartData);
      if (localCartData) {
        const cartIndex = product.index;
        const tProduct = _.cloneDeep(product.productData);
        if (cartIndex > -1 && localCartData[cartIndex]) {
          let updateCartProduct = _.cloneDeep(localCartData[cartIndex]);
          updateCartProduct.orderType = product.orderType;
          updateCartProduct.selectedAttributes = [];
          updateCartProduct.selectedCombinationId = [];
          updateCartProduct.selectedComboProducts = [];
          updateCartProduct.customerNote = customerNote;
          updateCartProduct.selectedPrice = tProduct.price;
          updateCartProduct.selectedOriginalPrice = tProduct.price;
          /// Attributes
          if (shopTypes.isShop) {
            let selectedAttributeOptions = [];
            if (product.attributes.length > 0) {
              await product.attributes.map((att) => {
                let selectedAttribute = { ...att, options: [] };
                let selectedOptions = false;
                let attId = att.id;
                att.options?.map((opt) => {
                  if (opt.isSelected) {
                    selectedOptions = true;
                    selectedAttribute.options.push({
                      ...opt,
                      attributesXml: null,
                    });
                  }
                });

                let attributeOption = {
                  attributeId: attId,
                  attributeOptionId: att.options.find(
                    (o) => o.isSelected === true
                  ).attributeOptionId,
                };
                selectedAttributeOptions.push(attributeOption);

                if (selectedOptions) {
                  updateCartProduct.selectedAttributes.push(selectedAttribute);
                }
              });
            }

            const findCombineRow = await tProduct.attributeCombinations.find(
              (row) => _.isEqual(selectedAttributeOptions, row.sortedXml)
            );
            if (findCombineRow) {
              updateCartProduct.selectedOriginalPrice +=
                findCombineRow.priceAdjustment;
              updateCartProduct.selectedPrice += findCombineRow.priceAdjustment;
              updateCartProduct.selectedCombinationId.push(findCombineRow.id);
              updateCartProduct.maxQuantity = findCombineRow.quantity;
            }
          } else if (shopTypes.isRestaurant) {
            product.attributes.map((attribute) => {
              let selectedAttribute = { ...attribute, options: [] };
              let selectedOptions = false;
              attribute.options?.map((option) => {
                if (option.isSelected) {
                  selectedOptions = true;
                  selectedAttribute.options.push(option);
                  updateCartProduct.selectedCombinationId.push(
                    option.combinationId
                  );
                  updateCartProduct.selectedPrice += option.price;
                  updateCartProduct.selectedOriginalPrice += option.price;
                }
              });
              if (selectedOptions) {
                updateCartProduct.selectedAttributes.push(selectedAttribute);
              }
            });
          }

          /// For Combo Set
          if (tProduct.type === 3 || tProduct.type === 4) {
            tProduct.comboProducts?.map((combo) => {
              if (combo.isSelected) {
                let comboXml = [];
                combo.attributes?.map((attribute) => {
                  attribute.options?.map((option) => {
                    if (option.isSelected) {
                      let xml = {
                        attributeId: option.attributeId,
                        attributeOptionId: option.attributeOptionId,
                        attributeName: attribute.name,
                        optionName: option.optionName,
                      };
                      comboXml.push(xml);
                    }
                  });
                });
                updateCartProduct.selectedComboProducts.push(combo);
              }
            });
          }
          console.log(
            "updateCartProduct.selectedPrice ",
            updateCartProduct.selectedPrice
          );
          console.log(
            "updateCartProduct.selectedOriginalPrice ",
            updateCartProduct.selectedOriginalPrice
          );
          // Update Local Cart
          localCartData[cartIndex] = updateCartProduct;
          changeCartData(localCartData, shopId);
          setCustomerNote("");
          setIsShow(false);
        }
      }

      setIsLoadDoSubmit(false);
    } catch (error) {
      console.log(error);
      NotificationManager.error(
        "product detail add to cart failed ",
        languageData.error
      );
    }
  };

  const onChangeComboAttributeOption = async (comIdx, attIdx, optIdx) => {
    try {
      let tProduct = { ...product };
      let tComboProducts = [...tProduct.productData.comboProducts];
      let findComAtt = tComboProducts[comIdx].attributes[attIdx];

      if (findComAtt) {
        await findComAtt.options.map((item, oIdx) => {
          if (findComAtt.type === 1) {
            item.isSelected = false;
            if (oIdx === optIdx) {
              item.isSelected = true;
            }
          } else {
            if (oIdx === optIdx) {
              item.isSelected = !item.isSelected;
            }
          }
        });
      }
      tComboProducts[comIdx].attributes[attIdx] = findComAtt;
      tProduct.productData.comboProducts = tComboProducts;

      setProduct(tProduct);
    } catch (error) {
      console.log("cart edit product error: " + error);
    }
  };

  const checkSubComboQty = (sProduct, sComboProducts) => {
    let cusSelectedQty = 0;
    product.productData?.comboProducts.map((c) => {
      if (c.isSelected || c.isDefault) {
        cusSelectedQty += 1;
      }
    });

    return product.productData?.maxProduct - cusSelectedQty;
  };

  const checkComboSubQuantity = () => {
    let isValid = false;
    if (product.productData?.isComboSet) {
      if (product.productData?.type == 4) {
        isValid = true;
        let cusSelectedQty = 0;
        product.productData?.comboProducts.map((combo) => {
          if (combo.isSelected || combo.isDefault) {
            cusSelectedQty += 1;
          }
        });
        if (cusSelectedQty == product.productData?.maxProduct) {
          isValid = false;
        }
      }
    }
    return isValid;
  };

  const handleChangeAttributeOptionForShop = async (attributeId, optionId) => {
    try {
      let tProduct = _.cloneDeep(product);
      let tPrice = tProduct.productData.price;
      let tSalePrice = tProduct.productData.salePrice;

      let selectedAttributeOptions = [];
      for (let att of tProduct.attributes) {
        let attId = att.id;
        let optId = null;
        if (att.id === attributeId) {
          att.options.map((opt) => {
            opt.isSelected = opt.attributeOptionId === optionId;
            if (opt.isSelected) {
              optId = opt.attributeOptionId;
            }
          });
        }

        let attributeOption = {
          attributeId: attId,
          attributeOptionId: att.options.find((o) => o.isSelected === true)
            .attributeOptionId,
        };
        selectedAttributeOptions.push(attributeOption);
      }

      const sortSelectedOptions = selectedAttributeOptions.sort(
        (a, b) => a.attributeId - b.attributeId
      );

      const findCombineRow =
        await tProduct.productData.attributeCombinations.find(
          (row) =>
            JSON.stringify(row.sortedXml) ===
            JSON.stringify(sortSelectedOptions)
        );

      if (findCombineRow) {
        tPrice += findCombineRow.priceAdjustment;
        tSalePrice += findCombineRow.priceAdjustment;
        setMaxQuantity(findCombineRow.maxQuantity);
        setIsOutOfStock(findCombineRow.isOutOfStock);
      }

      setPrice(tPrice);
      setSalePrice(tSalePrice);
      setProduct(tProduct);
    } catch (error) {
      console.log("handleChangeAttributeOptionForShop error ", error);
    }
  };

  if (isShow && product.productData)
    return (
      <Modal
        isOpen={isShow}
        className="modal-dialog-centered product-list_wrapper product-attr-modal"
        id="modal-dialog"
      >
        <ModalHeader className="justify-content-end pb-2">
          <div onClick={toggle} className="modal-btn-close">
            <InlineIcon icon={closeIcon} className="close-icon" />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="bg-light-card mb-3 d-flex flex-row">
            <div className="product-info_holder" style={{ width: "100%" }}>
              <div className="product-img_holder">
                <Image name={product.productData.image} alt="Shop Image" />
              </div>
              <div className="product-item_holder">
                <div className="item-name">
                  <span>{product.productData.productName}</span>
                </div>
                <div className="d-flex flex-row align-items-center">
                  {/* For Original Price and discount price */}
                  {product.isDiscountItem && salePrice < price ? (
                    <>
                      <span className="item-price">
                        {numberWithCommas(salePrice || 0)}{" "}
                        {languageData[CURRENCY_CODE]}
                      </span>
                      <span className="discount-price">
                        {numberWithCommas(price || 0)}{" "}
                        {languageData[CURRENCY_CODE]}
                      </span>
                    </>
                  ) : (
                    <span className="item-price">
                      {numberWithCommas(price || 0)}{" "}
                      {languageData[CURRENCY_CODE]}
                    </span>
                  )}
                  {/* <div className="item-price">
                    <span>
                      {numberWithCommas(product.productData.price || 0)}{" "}
                      {languageData[CURRENCY_CODE]}
                    </span>
                  </div> */}
                  <div className="ms-2">
                    <span className="reward-point_wrap">
                      {product.productData.givenPoint} {languageData.pts}
                    </span>
                  </div>
                </div>
                {shopTypes.isShop ? (
                  isOutOfStock ? (
                    <div style={{ color: "red" }}>
                      {languageData.outOfStock}
                    </div>
                  ) : !isOutOfStock && maxQuantity > 0 ? (
                    <div style={{ color: "red" }}>
                      {languageData.maxQuantity} : {maxQuantity}
                    </div>
                  ) : null
                ) : null}
                {product.productData.description && (
                  <p className="item-description mb-0">
                    {product.productData.description}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* For Attributes */}
          {shopTypes.isShop && (
            <>
              {product.attributes?.map((attribute, attIdx) => {
                return (
                  <div key={attIdx}>
                    <div className="title mt-2">
                      <span>{attribute.name}</span>
                    </div>

                    <div className="product-attr-info">
                      {attribute.options?.map((option, optIdx) => {
                        if (
                          option.color !== "#000000" &&
                          option.color !== "" &&
                          option.color
                        )
                          return (
                            <div key={optIdx} className="price-adjustment">
                              <div className="form-check mb-0">
                                <input
                                  className="form-check-input"
                                  type={
                                    attribute.type === 1 ? "radio" : "checkbox"
                                  }
                                  onChange={() =>
                                    handleChangeAttributeOptionForShop(
                                      attribute.id,
                                      option.attributeOptionId
                                    )
                                  }
                                  checked={option.isSelected}
                                />
                                <label className="form-check-label ms-2">
                                  {option.optionName}
                                </label>
                              </div>
                              <div>
                                <span className="price">
                                  {option.price > 0 && (
                                    <span className="price">
                                      {" "}
                                      +
                                      {option.price +
                                        " " +
                                        languageData[CURRENCY_CODE]}
                                    </span>
                                  )}
                                </span>
                              </div>
                            </div>
                          );
                        return (
                          <div key={optIdx} className="price-adjustment">
                            <div className="form-check mb-0">
                              <input
                                className="form-check-input"
                                type={
                                  attribute.type === 1 ? "radio" : "checkbox"
                                }
                                onChange={() =>
                                  handleChangeAttributeOptionForShop(
                                    attribute.id,
                                    option.attributeOptionId
                                  )
                                }
                                checked={option.isSelected}
                              />
                              <label className="form-check-label ms-2">
                                {option.optionName}
                              </label>
                            </div>
                            <div>
                              <span className="price">
                                <b>
                                  {option.price > 0 && (
                                    <span className="price">
                                      {" "}
                                      +
                                      {option.price +
                                        " " +
                                        languageData[CURRENCY_CODE]}
                                    </span>
                                  )}
                                </b>
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </>
          )}

          {shopTypes.isRestaurant && (
            <>
              {product.attributes?.map((item, attIdx) => {
                return (
                  <div key={attIdx}>
                    <div className="title mt-2">
                      <span>{item.name}</span>
                    </div>

                    <div className="product-attr-info">
                      {item.options?.map((option, optIdx) => {
                        if (
                          option.color !== "#000000" &&
                          option.color !== "" &&
                          option.color
                        )
                          return (
                            <div key={optIdx} className="price-adjustment">
                              <div className="form-check mb-0">
                                <input
                                  className="form-check-input"
                                  type={item.type === 1 ? "radio" : "checkbox"}
                                  onChange={() =>
                                    handleSelectAttribute(
                                      attIdx,
                                      option.attributeOptionId
                                    )
                                  }
                                  checked={option.isSelected}
                                />
                                <label className="form-check-label ms-2">
                                  {option.optionName}
                                </label>
                              </div>
                              <div>
                                <span className="price">
                                  {option.price > 0 && (
                                    <span className="price">
                                      {" "}
                                      +
                                      {option.price +
                                        " " +
                                        languageData[CURRENCY_CODE]}
                                    </span>
                                  )}
                                </span>
                              </div>
                            </div>
                          );
                        return (
                          <div key={optIdx} className="price-adjustment">
                            <div className="form-check mb-0">
                              <input
                                className="form-check-input"
                                type={item.type === 1 ? "radio" : "checkbox"}
                                onChange={() =>
                                  handleSelectAttribute(
                                    attIdx,
                                    option.attributeOptionId
                                  )
                                }
                                checked={option.isSelected}
                              />
                              <label className="form-check-label ms-2">
                                {option.optionName}
                              </label>
                            </div>
                            <div>
                              <span className="price">
                                <b>
                                  {option.price > 0 && (
                                    <span className="price">
                                      {" "}
                                      +
                                      {option.price +
                                        " " +
                                        languageData[CURRENCY_CODE]}
                                    </span>
                                  )}
                                </b>
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </>
          )}
          {/* For Combo Set Products */}
          {product.productData.type === 3 || product.productData.type === 4 ? (
            <>
              {/* Main Menu */}
              {product.productData.comboProducts?.length > 0 ? (
                <>
                  {/* <div className="title">
                    <span>{languageData.mainMenu || "Main Menu"}</span>
                  </div> */}
                  {product.productData.comboProducts?.map((item, comIdx) => {
                    let comboProduct = item;
                    let comboAttributes = item.attributes;

                    if (comboProduct.isDefault)
                      return (
                        <div
                          key={comIdx}
                          className="product-list-item-combo mt-3"
                        >
                          <div className="product-list-info-combo">
                            <Image
                              name={comboProduct.image}
                              className="product-img-combo"
                            />
                            <div>
                              <span className="item-name">
                                {comboProduct.productName}
                              </span>
                            </div>
                          </div>
                          {comboAttributes.map((att, attIdx) => {
                            const attOptionGroupName = att.name + Math.random();
                            if (att.options[0] !== undefined)
                              return (
                                <div key={attIdx}>
                                  <div className="small-label fw-bold">
                                    {att.name}
                                  </div>
                                  <div className="d-flex align-items-center flex-wrap">
                                    {att.options.map((option, optIdx) => {
                                      return (
                                        <div
                                          key={optIdx}
                                          className="d-flex align-items-center mt-2 me-3"
                                        >
                                          {/* {att.type === 1 ? (
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name={attOptionGroupName}
                                              value={option.name}
                                              defaultChecked={option.isSelected}
                                              onChange={() =>
                                                onChangeComboAttributeOption(
                                                  comIdx,
                                                  attIdx,
                                                  optIdx
                                                )
                                              }
                                            />
                                          ) : (
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              name={attOptionGroupName}
                                              checked={option.isSelected}
                                              readOnly
                                            />
                                          )} */}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            // name={attOptionGroupName}
                                            checked={option.isSelected}
                                            readOnly
                                          />
                                          <label className="form-check-label">
                                            {option.optionName}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                          })}
                        </div>
                      );
                  })}
                  {/* For Combo Sub Menu */}
                  {product.productData?.hasComboSubMenu ? (
                    <>
                      <div className="title mt-2">
                        {/* <span>{languageData.subMenu || "Sub Menu"}</span> */}
                        {checkSubComboQty() > 0 && (
                          <small className="text-warning ms-2">
                            {languageData.comboSetInfoLeft} {checkSubComboQty()}{" "}
                            {languageData.comboSetInfoRight}
                          </small>
                        )}
                        {product.error && (
                          <small className="text-danger ms-2">
                            {product.error}
                          </small>
                        )}
                      </div>

                      {product.productData?.comboProducts?.map(
                        (item, comIdx) => {
                          let comboProduct = item;
                          let comboAttributes = item.attributes;
                          if (!comboProduct.isDefault)
                            return (
                              <div
                                key={comboProduct.id}
                                className="product-list-item-combo"
                              >
                                <div className="product-list-info-combo">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="submenu"
                                    checked={comboProduct.isSelected}
                                    onChange={() =>
                                      onClickSubComboProduct(comboProduct.id)
                                    }
                                  />
                                  <Image
                                    name={comboProduct.image}
                                    className="product-img-combo"
                                  />
                                  <div>
                                    <span className="item-name">
                                      {comboProduct.productName}
                                    </span>
                                  </div>
                                </div>
                                {comboAttributes.map((att, attIdx) => {
                                  const attOptionGroupName =
                                    att.name + Math.random();
                                  if (att.options[0] !== undefined)
                                    return (
                                      <div key={attIdx}>
                                        <div className="small-label fw-bold">
                                          {att.name}
                                        </div>
                                        <div className="d-flex align-items-center flex-wrap">
                                          {att.options.map((option, optIdx) => {
                                            return (
                                              <div
                                                key={optIdx}
                                                className="d-flex align-items-center mt-2 me-3"
                                              >
                                                {/* {att.type ===
                                                  1 ? (
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name={
                                                        attOptionGroupName
                                                      }
                                                      value={
                                                        option.name
                                                      }
                                                      defaultChecked={
                                                        option.isSelected
                                                      }
                                                      onChange={() =>
                                                        onChangeProductComboAttributeOption(
                                                          comIdx,
                                                          comIdx,
                                                          attIdx,
                                                          optIdx
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      name={
                                                        attOptionGroupName
                                                      }
                                                      checked={
                                                        option.isSelected
                                                      }
                                                      readOnly
                                                    />
                                                  )} */}
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  checked={true}
                                                  readOnly
                                                />
                                                <label className="form-check-label">
                                                  {option.optionName}
                                                </label>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    );
                                })}
                              </div>
                            );
                        }
                      )}
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
          {/* For Customer Note */}
          <div className="title">
            <span>{languageData.customerNote}</span>
          </div>
          <Textarea
            className="form-control mb-2"
            style={{ height: 100 }}
            value={customerNote}
            placeholder={languageData.pleaseEnterCustomerNote}
            onChange={(e) => {
              setCustomerNote(e.target.value);
            }}
          />
          {/* For Pos Order Type Can Select (DINE IN or TAKE AWAY) */}
          {isPos && (
            <>
              <div className="bg-light-card">
                <p className="small-title mb-2">
                  {languageData.ifyouwanttotakeawaythisproduct}
                </p>
                <div className="form-check mb-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        orderType: e.target.checked ? 1 : 2,
                      });
                    }}
                    checked={product.orderType === 1}
                  />
                  <label className="form-check-label ms-2">
                    {languageData.setAsTakeAway}
                  </label>
                </div>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter className="d-flex flex-row justify-content-center align-items-center product-detail">
          {/* <div className="qty-input">
              <div className="input-group">
                <span className="input-group-text me-1">
                  <FontAwesomeIcon
                    icon={faMinus}
                    className="icon"
                    onClick={() => {
                      if (product.quantity > 1) {
                        setProduct({
                          ...product,
                          quantity: product.quantity - 1,
                        });
                      }
                    }}
                  />
                </span>
                <input
                  type="text"
                  className="form-control"
                  value={product.quantity}
                  readOnly
                />
                <span className="input-group-text ms-1">
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="icon"
                    onClick={() => {
                      setProduct({
                        ...product,
                        quantity: product.quantity + 1,
                      });
                    }}
                  />
                </span>
              </div>
            </div> */}

          <Button
            className="btn btn-primary w-100"
            label={languageData.addToCart}
            onClick={doSubmit}
            isLoad={isLoadDoSubmit}
            disabled={
              isLoadDoSubmit ||
              checkComboSubQuantity() ||
              (shopTypes.isShop && product.selectedQuantity > maxQuantity)
            }
          />
        </ModalFooter>
      </Modal>
    );
}
