import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { NotificationManager } from "react-notifications";
import CommonContext from "../../context/CommonContext";
import CartContext from "../../context/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { InlineIcon } from "@iconify/react";
import AlertCircleIcon from "@iconify/icons-mdi/alert-circle";

/// Components
import { Image } from "../common";
/// Utils
/// Config Data
import config from "../../config.json";
import _ from "lodash";
const { CURRENCY_CODE } = config;

export default function DiscountProductModal({
  isShow = false,
  setIsShow,
  data = [],
  maxQuantity = 0,
  customSelectedQuantity = 0,
  shopId = 0,
  discountId,
  productIds = [],
  pairedProducts = [],
  shopTypes,
  cartData,
  setCartData,
  setIsLoadAddToCart,
  setLoadingMessage,
  giveAwayDiscounts,
  setGiveAwayDiscounts,
  currentGiveAwayDiscountIndex,
  title = null,
  isPos = false,
}) {
  const commonContext = useContext(CommonContext);
  const { languageData } = commonContext;

  const cartContext = useContext(CartContext);

  const [products, setProducts] = useState(data);
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [isLoadDoSubmit, setIsLoadDoSubmit] = useState(false);

  useEffect(() => {
    setProducts(data);
    setSelectedQuantity(customSelectedQuantity);
  }, [data, customSelectedQuantity]);

  useEffect(() => {
    checkCustomDPSelectedQuantity(products);
  }, [products]);

  const checkCustomDPSelectedQuantity = (sProducts) => {
    let cusSelectedQty = 0;
    sProducts.map((item) => {
      if (item.isSelected) {
        cusSelectedQty += item.quantity;
      }
    });
    setSelectedQuantity(cusSelectedQty);
  };

  const handleSelectedProduct = (id) => {
    try {
      let tProducts = [...products];
      let cusSelectedQty = 0;

      tProducts.map((item) => {
        if (item.id !== id) {
          if (item.isSelected) {
            cusSelectedQty += item.quantity;
            if (isPos && !item.orderType) {
              item.orderType = 2;
            }
          }
        } else {
          item.quantity = 0;
        }
      });

      if (cusSelectedQty < maxQuantity) {
        tProducts.map((item) => {
          if (item.id === id) {
            item.isSelected = !item.isSelected;
            if (!item.isSelected) {
              item.quantity = 0;
            } else {
              item.quantity += maxQuantity - cusSelectedQty;
              if (isPos && !item.orderType) {
                item.orderType = 2;
              }
            }
          }
        });
      }

      // checkCustomDPSelectedQuantity(tProducts);
      setProducts(tProducts);
    } catch (error) {
      //   console.log(error);
    }
  };

  const handleChangeProductQuantity = (itemIdx, action) => {
    let tProducts = [...products];
    const findProduct = tProducts.find((item, idx) => idx === itemIdx);

    if (findProduct) {
      if (action === "-") {
        tProducts[itemIdx].quantity = tProducts[itemIdx].quantity - 1;

        if (tProducts[itemIdx].quantity === 0) {
          tProducts[itemIdx].isSelected = false;
        }
      } else {
        if (selectedQuantity < maxQuantity) {
          tProducts[itemIdx].quantity = tProducts[itemIdx].quantity + 1;
        }
      }
      checkCustomDPSelectedQuantity(tProducts);
      setProducts(tProducts);
    }
  };

  const handleSelectProductAttribute = async (itemIdx, attIdx, optionId) => {
    let tProducts = _.cloneDeep(products);
    let findAtt = tProducts[itemIdx].attributes[attIdx];
    if (findAtt) {
      await Promise.all(
        findAtt.options.map(async (item) => {
          if (findAtt.type === 1) {
            item.isSelected = false;
            if (item.attributeOptionId === optionId) {
              item.isSelected = true;
            }
          } else if (findAtt.type === 2) {
            if (item.attributeOptionId === optionId) {
              if (item.isSelected) {
                const findCurrentOpt = await findAtt.options.find(
                  (opt) =>
                    opt.attributeOptionId !== optionId && opt.isSelected == true
                );
                if (findCurrentOpt) {
                  item.isSelected = false;
                }
              } else {
                item.isSelected = true;
              }
            }
          } else {
            if (item.attributeOptionId === optionId) {
              item.isSelected = !item.isSelected;
            }
          }
        })
      ).then(() => {
        tProducts[itemIdx].attributes[attIdx] = findAtt;

        setProducts(tProducts);
      });
    }
    tProducts[itemIdx].attributes[attIdx] = findAtt;

    setProducts(tProducts);
  };

  const handleChangeAttributeOptionForShop = async (
    itemIdx,
    attributeId,
    optionId
  ) => {
    try {
      let tProducts = _.cloneDeep(products);
      let tProduct = tProducts[itemIdx];
      let tPrice = tProduct.price;
      let tSalePrice = tProduct.salePrice;

      let selectedAttributeOptions = [];

      await tProduct.attributes.map((att) => {
        let attId = att.id;
        let optId = null;
        if (att.id === attributeId) {
          att.options.map((opt) => {
            opt.isSelected = opt.attributeOptionId === optionId;
            if (opt.isSelected) {
              optId = opt.attributeOptionId;
            }
          });
        }
        let attributeOption = {
          attributeId: attId,
          attributeOptionId: att.options.find((o) => o.isSelected === true)
            .attributeOptionId,
        };
        selectedAttributeOptions.push(attributeOption);
      });

      for (let findCombineRow of tProduct.attributeCombinations) {
        if (
          _.isEqual(
            JSON.parse(findCombineRow.attributesXml),
            selectedAttributeOptions
          )
        ) {
          tPrice += findCombineRow.priceAdjustment;
          tSalePrice += findCombineRow.priceAdjustment;
          tProduct.maxQuantity = findCombineRow.quantity;
          tProduct.isOutOfStock = findCombineRow.isOutOfStock;
        }
      }
      if (tProduct.isOutOfStock) {
        tProduct.isSelected = false;
      }
      setProducts(tProducts);
    } catch (error) {
      console.log("handleChangeAttributeOptionForShop error ", error);
    }
  };

  const onClickSubComboProduct = (productIdx, discountComboId) => {
    let tProducts = [...products];
    const findProduct = tProducts[productIdx];

    if (findProduct) {
      let cusSelectedQty = 0;
      findProduct.comboProducts.map((c) => {
        if (c.isSelected) {
          if (c.id !== discountComboId) {
            cusSelectedQty += 1;
          }
        }
      });

      if (cusSelectedQty < findProduct.maxProduct) {
        findProduct.comboProducts.map((c) => {
          if (!c.isDefault) {
            if (c.id === discountComboId) {
              c.isSelected = !c.isSelected;
            }
          }
        });
      }

      tProducts[productIdx] = findProduct;
      setProducts(tProducts);
    }
  };

  const onChangeProductComboAttributeOption = (
    discountIdx,
    comIdx,
    attIdx,
    optIdx
  ) => {
    let tProducts = [...products];
    let findComboProductAttribute =
      tProducts[discountIdx].comboProducts[comIdx].attributes[attIdx];

    if (findComboProductAttribute) {
      findComboProductAttribute.options.map((item, oIdx) => {
        if (findComboProductAttribute.type === 1) {
          item.isSelected = false;
          if (oIdx === optIdx) {
            item.isSelected = true;
          }
        } else {
          if (oIdx === optIdx) {
            item.isSelected = !item.isSelected;
          }
        }
      });
    }

    tProducts[discountIdx].comboProducts[comIdx].attributes[attIdx] =
      findComboProductAttribute;
    setProducts(tProducts);
  };

  const doSubmit = async () => {
    let finalData = [];
    try {
      setLoadingMessage(languageData.addItemToCart);
      setIsLoadAddToCart(true);
      setIsLoadDoSubmit(true);
      /// Add Discount Product To Cart to local Storage
      let localCartData = [...cartData];
      let paired = [...pairedProducts];

      for (let item of products) {
        if (item.isSelected) {
          let itemPairedProducts = [];
          let inCompletePairs = paired.filter((pair) => {
            return !pair.isComplete;
          });
          if (inCompletePairs.length > 0) {
            let pairedProductIds = [];
            let itemCheckedPairedQuantity = item.quantity;

            for (let inCompletePair of inCompletePairs) {
              if (itemCheckedPairedQuantity > 0) {
                if (itemCheckedPairedQuantity >= inCompletePair.yMaxQuantity) {
                  inCompletePair.isComplete = true;
                  itemCheckedPairedQuantity -= inCompletePair.yMaxQuantity;
                  pairedProductIds.push(inCompletePair);
                } else {
                  inCompletePair.yMaxQuantity -= itemCheckedPairedQuantity;
                  let clonePair = _.cloneDeep(inCompletePair);
                  clonePair.yMaxQuantity = itemCheckedPairedQuantity;
                  pairedProductIds.push(clonePair);
                }
              }
            }

            if (pairedProductIds.length > 0) {
              const cartProductData = {
                shopId: shopId,
                productData: item,
                isDiscountProduct: true,
                selectedAttributes: [],
                selectedCombinationId: [],
                selectedQuantity: item.quantity,
                quantity: item.quantity,
                selectedPrice: 0,
                selectedComboProducts: [],
                attributes: item.attributes,
                discountId: discountId,
                orderType: item.orderType,
                pairedProducts: pairedProductIds,
              };
              console.log("cartProductData is ", cartProductData);
              /// Attributes
              for (let attribute of item.attributes) {
                let selectedAttribute = { ...attribute, options: [] };
                let selectedOptions = false;
                for (let option of attribute.options) {
                  if (option.isSelected) {
                    selectedOptions = true;
                    selectedAttribute.options.push(option);
                    cartProductData.selectedCombinationId.push(
                      option.combinationId
                    );
                    cartProductData.selectedPrice += option.price;
                  }
                }
                if (selectedOptions) {
                  cartProductData.selectedAttributes.push(selectedAttribute);
                }
              }

              /// For Combo Set
              if (item.isComboSet) {
                item.comboProducts.map((combo) => {
                  if (combo.isSelected) {
                    // let comboXml = [];
                    // combo.attributes?.map((attribute) => {
                    //   attribute.options?.map((option) => {
                    //     if (option.isSelected) {
                    //       let xml = {
                    //         attributeId: option.attributeId,
                    //         attributeOptionId: option.attributeOptionId,
                    //         attributeName: attribute.name,
                    //         optionName: option.optionName,
                    //       };
                    //       comboXml.push(xml);
                    //     }
                    //   });
                    // });
                    cartProductData.selectedComboProducts.push(combo);
                  }
                });
              }
              localCartData.push(cartProductData);
            }
          }
        }
      }

      setCartData(localCartData);
      let gDiscounts = _.cloneDeep(giveAwayDiscounts);
      let currentDiscounts = await gDiscounts.filter(
        (d) => d.discountId !== discountId
      );
      setGiveAwayDiscounts(currentDiscounts);
      setIsLoadDoSubmit(false);
      setIsLoadAddToCart(false);
      setIsShow(false);
    } catch (error) {
      setIsLoadDoSubmit(false);
      setIsLoadAddToCart(false);
      setIsShow(false);
      console.log("DISCOUNT MODAL ERROR IS ", error);
      NotificationManager.error(
        "Discount item add to cart failed",
        languageData.error
      );
    }
  };

  const checkProductSubComboQty = (sProduct, sComboProducts) => {
    let cusSelectedQty = 0;
    sComboProducts.map((c) => {
      if (c.isSelected || c.isDefault) {
        cusSelectedQty += 1;
      }
    });

    return sProduct.maxProduct - cusSelectedQty;
  };

  const checkGiveAwayProductSubComboQuantity = () => {
    let isInvalid = false;
    products.map(async (item) => {
      if (item.isSelected) {
        if (item.isComboSet) {
          if (item.type == 4) {
            isInvalid = true;
            let cusSelectedQty = 0;
            item.comboProducts.map((combo) => {
              if (combo.isSelected || combo.isDefault) {
                cusSelectedQty += 1;
              }
            });
            if (cusSelectedQty == item.maxProduct) {
              isInvalid = false;
            }
          }
        }
        if (item.isOutOfStock) {
          isInvalid = true;
        }
      }
    });
    console.log("isInvalid is ", isInvalid);
    return isInvalid;
  };

  const handleChangeProductOrderType = (itemIdx, type) => {
    let tProducts = _.cloneDeep(products);
    const findProduct = tProducts.find((item, idx) => idx === itemIdx);
    if (findProduct) {
      if (type == 1) {
        tProducts[itemIdx].orderType = 2;
      } else {
        tProducts[itemIdx].orderType = 1;
      }
      setProducts(tProducts);
    }
  };

  return (
    <Modal
      isOpen={isShow}
      className="modal-dialog-centered product-list_wrapper product-attr-modal"
      id="modal-dialog"
    >
      <ModalBody style={{ maxHeight: "500px" }}>
        <div className="mt-3">
          <h4 className="title mb-1">
            {languageData.heyyouhavegiveawayproduct}
          </h4>
          <p className="small-text mb-2">
            {languageData.youcanchoice} {maxQuantity} {languageData.quan}.
          </p>
          <div className="product-list_wrapper product-attr-modal">
            <div className="row gx-3">
              {products.map((item, itemIdx) => {
                return (
                  <div key={item.id} className="col-md-12">
                    <div className="discount-product-list-item">
                      <div className="product-list-item flex-column">
                        <div className="product-info_holder">
                          {!item.isOutOfStock && (
                            <div className="me-3">
                              <input
                                type="checkbox"
                                name="discount-product"
                                className="form-check-input"
                                checked={item.isSelected}
                                onChange={() => handleSelectedProduct(item.id)}
                              />
                            </div>
                          )}
                          <div className="product-img_holder">
                            <Image name={item.image} alt="Shop Image" />
                            {item.isOutOfStock && (
                              <div className="out-of-stock-wrap">
                                <InlineIcon
                                  icon={AlertCircleIcon}
                                  className="icon"
                                />
                                <p className="mt-2">
                                  {languageData.outOfStock}
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="product-item_holder">
                            <div className="item-name">
                              <span>{item.productName} </span>
                            </div>
                            <div className="dis-qty-wrap">
                              {item.quantity > 0 ? (
                                <div
                                  className="qty-input me-0"
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <div className="input-group me-0 mt-0">
                                    <span
                                      className="input-group-text"
                                      onClick={() =>
                                        handleChangeProductQuantity(
                                          itemIdx,
                                          "-"
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faMinus}
                                        className="icon"
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      readOnly
                                      className="form-control"
                                      value={item.quantity}
                                    />
                                    <span
                                      className="input-group-text"
                                      onClick={() =>
                                        handleChangeProductQuantity(
                                          itemIdx,
                                          "+"
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        className="icon"
                                      />
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {item.isSelected ? (
                                    <span
                                      className="plus-btn"
                                      onClick={() =>
                                        handleChangeProductQuantity(
                                          itemIdx,
                                          "+"
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon icon={faPlus} />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        {shopTypes.isShop && (
                          <>
                            {item.attributes?.map((attribute, attIdx) => {
                              const attributeGroupName =
                                attribute.name + Math.random();
                              return (
                                <div key={attribute.id}>
                                  <div className="title">
                                    <span>{attribute.name}</span>
                                  </div>

                                  <div className="product-attr-info">
                                    {attribute.options?.map(
                                      (option, optIdx) => {
                                        return (
                                          <div key={optIdx}>
                                            <div className="price-adjustment">
                                              <div className="form-check mb-0">
                                                <input
                                                  className="form-check-input"
                                                  name={attributeGroupName}
                                                  type={"radio"}
                                                  onChange={() =>
                                                    handleChangeAttributeOptionForShop(
                                                      itemIdx,
                                                      attribute.id,
                                                      option.attributeOptionId
                                                    )
                                                  }
                                                  checked={option.isSelected}
                                                />
                                                <label className="form-check-label ms-2">
                                                  {option.optionName}
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                        {shopTypes.isRestaurant && (
                          <>
                            {item.attributes?.map((attribute, attIdx) => {
                              const attributeGroupName =
                                attribute.name + Math.random();
                              return (
                                <div key={attribute.id}>
                                  <div className="title">
                                    <span>{attribute.name}</span>
                                  </div>

                                  <div className="product-attr-info">
                                    {attribute.options?.map(
                                      (option, optIdx) => {
                                        if (
                                          option.color !== "#000000" &&
                                          option.color !== "" &&
                                          option.color
                                        )
                                          return (
                                            <div key={optIdx}>
                                              <div className="price-adjustment">
                                                <div className="form-check mb-0">
                                                  <input
                                                    className="form-check-input"
                                                    name={attributeGroupName}
                                                    type={
                                                      attribute.type === 1
                                                        ? "radio"
                                                        : "checkbox"
                                                    }
                                                    onChange={() =>
                                                      handleSelectProductAttribute(
                                                        itemIdx,
                                                        attIdx,
                                                        option.attributeOptionId
                                                      )
                                                    }
                                                    checked={option.isSelected}
                                                  />
                                                  <label className="form-check-label ms-2">
                                                    {option.optionName}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          );

                                        return (
                                          <div
                                            key={optIdx}
                                            className="price-adjustment"
                                          >
                                            <div className="form-check mb-0">
                                              <input
                                                className="form-check-input"
                                                name={attributeGroupName}
                                                type={
                                                  attribute.type === 1
                                                    ? "radio"
                                                    : "checkbox"
                                                }
                                                onChange={() =>
                                                  handleSelectProductAttribute(
                                                    itemIdx,
                                                    attIdx,
                                                    option.attributeOptionId
                                                  )
                                                }
                                                // defaultChecked={option.isSelect}
                                                checked={option.isSelected}
                                              />
                                              <label className="form-check-label ms-2">
                                                {option.optionName}
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}

                        {/* For Combo Set Product Discount */}
                        {item.isComboSet ? (
                          <div className="mt-2">
                            {item.comboProducts.length > 0 ? (
                              <>
                                {/* Main Menu Combo set product */}
                                {item.comboProducts.map((item, comIdx) => {
                                  let itemProduct = item;
                                  let itemAttributes = item.attributes;
                                  if (itemProduct.isDefault)
                                    return (
                                      <div
                                        key={itemProduct.id}
                                        className="product-list-item-combo"
                                      >
                                        <div className="product-list-info-combo">
                                          <Image
                                            name={itemProduct.image}
                                            className="product-img-combo"
                                          />
                                          <div>
                                            <span className="item-name">
                                              {itemProduct.productName}
                                            </span>
                                          </div>
                                        </div>
                                        {itemAttributes.map((att, attIdx) => {
                                          const attributeGroupName =
                                            att.name + Math.random();
                                          if (att.options[0] !== undefined)
                                            return (
                                              <div key={attIdx}>
                                                <div className="small-label fw-bold">
                                                  {att.name}
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap">
                                                  {att.options.map(
                                                    (option, optIdx) => {
                                                      return (
                                                        <div
                                                          key={optIdx}
                                                          className="d-flex align-items-center mt-2 me-3"
                                                        >
                                                          {/* {att.type === 1 ? (
                                                            <input
                                                              className="form-check-input"
                                                              type="radio"
                                                              name={
                                                                attributeGroupName
                                                              }
                                                              value={
                                                                option.name
                                                              }
                                                              defaultChecked={
                                                                option.isSelected
                                                              }
                                                              onChange={() =>
                                                                onChangeProductComboAttributeOption(
                                                                  itemIdx,
                                                                  comIdx,
                                                                  attIdx,
                                                                  optIdx
                                                                )
                                                              }
                                                            />
                                                          ) : (
                                                            <input
                                                              className="form-check-input"
                                                              type="checkbox"
                                                              name={
                                                                attributeGroupName
                                                              }
                                                              checked={
                                                                option.isSelected
                                                              }
                                                              readOnly
                                                            />
                                                          )} */}
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={true}
                                                            readOnly
                                                          />
                                                          <label className="form-check-label">
                                                            {option.optionName}
                                                          </label>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </div>
                                            );
                                        })}
                                      </div>
                                    );
                                })}
                                {/* Sub Combo Set Product */}
                                {item.hasComboSubMenu ? (
                                  <>
                                    <div className="title">
                                      {checkProductSubComboQty(
                                        item,
                                        item.comboProducts
                                      ) > 0 && (
                                        <small className="text-warning ms-2">
                                          {languageData.comboSetInfoLeft}{" "}
                                          {checkProductSubComboQty(
                                            item,
                                            item.comboProducts
                                          )}{" "}
                                          {languageData.comboSetInfoRight}
                                        </small>
                                      )}
                                      {item.error && (
                                        <small className="text-danger ms-2">
                                          {item.error}
                                        </small>
                                      )}
                                    </div>
                                    {item.comboProducts.map((item, comIdx) => {
                                      let itemProduct = item;
                                      let itemAttributes = item.attributes;
                                      if (!itemProduct.isDefault)
                                        return (
                                          <div
                                            key={itemProduct.id}
                                            className="product-list-item-combo"
                                          >
                                            <div className="product-list-info-combo">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="submenu"
                                                checked={itemProduct.isSelected}
                                                onChange={() =>
                                                  onClickSubComboProduct(
                                                    itemIdx,
                                                    itemProduct.id
                                                  )
                                                }
                                              />
                                              <Image
                                                name={itemProduct.image}
                                                className="product-img-combo"
                                              />
                                              <div>
                                                <span className="item-name">
                                                  {itemProduct.productName}
                                                </span>
                                              </div>
                                            </div>
                                            {itemAttributes.map(
                                              (att, attIdx) => {
                                                const attOptionGroupName =
                                                  att.name + Math.random();
                                                if (
                                                  att.options[0] !== undefined
                                                )
                                                  return (
                                                    <div key={attIdx}>
                                                      <div className="small-label fw-bold">
                                                        {att.name}
                                                      </div>
                                                      <div className="d-flex align-items-center flex-wrap">
                                                        {att.options.map(
                                                          (option, optIdx) => {
                                                            return (
                                                              <div
                                                                key={optIdx}
                                                                className="d-flex align-items-center mt-2 me-3"
                                                              >
                                                                {/* {att.type ===
                                                                1 ? (
                                                                  <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name={
                                                                      attOptionGroupName
                                                                    }
                                                                    value={
                                                                      option.name
                                                                    }
                                                                    defaultChecked={
                                                                      option.isSelected
                                                                    }
                                                                    onChange={() =>
                                                                      onChangeProductComboAttributeOption(
                                                                        itemIdx,
                                                                        comIdx,
                                                                        attIdx,
                                                                        optIdx
                                                                      )
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    name={
                                                                      attOptionGroupName
                                                                    }
                                                                    checked={
                                                                      option.isSelected
                                                                    }
                                                                    readOnly
                                                                  />
                                                                )} */}
                                                                <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  checked={true}
                                                                  readOnly
                                                                />
                                                                <label className="form-check-label">
                                                                  {
                                                                    option.optionName
                                                                  }
                                                                </label>
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    </div>
                                                  );
                                              }
                                            )}
                                          </div>
                                        );
                                    })}
                                  </>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        ) : null}
                      </div>

                      {isPos && item.isSelected && (
                        <>
                          <div className="title">
                            <span>{languageData.notice}</span>
                          </div>
                          <div className="bg-light-card">
                            <p className="small-title mb-2">
                              {languageData.ifyouwanttotakeawaythisproduct}
                            </p>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type={"checkbox"}
                                onChange={(e) => {
                                  handleChangeProductOrderType(
                                    itemIdx,
                                    item.orderType
                                  );
                                }}
                                checked={item.orderType === 1}
                              />
                              <label
                                className="form-check-label ms-2"
                                onClick={() => {
                                  handleChangeProductOrderType(
                                    itemIdx,
                                    item.orderType
                                  );
                                }}
                              >
                                {languageData.setAsTakeAway}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="d-block">
        <button
          className="btn btn-primary w-100"
          disabled={
            maxQuantity !== selectedQuantity ||
            isLoadDoSubmit ||
            checkGiveAwayProductSubComboQuantity()
          }
          onClick={doSubmit}
        >
          {languageData.submit}
        </button>
      </ModalFooter>
    </Modal>
  );
}
