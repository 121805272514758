import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { calculateDistance } from "../../utils/location";
import { NotificationManager } from "react-notifications";
import { InlineIcon } from "@iconify/react";
import BicycleIcon from "@iconify/icons-mdi/moped";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { CommonContext } from "../../context/CommonContext";
// Services
import {
  getNearbyShop,
  getRecommendedShop,
  getShopReviewByShopId,
} from "./shopService";
import { getLocalStorageUserLocation } from "../../services/localStorage";
import { calculateDeliveryFee } from "../../services/orderService";
// Components
import { Loading, Image } from "../common";
import "../style/shop.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faStar } from "@fortawesome/free-solid-svg-icons";

import Bicycle from "../../assets/img/bicycle.png";
import IconDiscount from "../../assets/img/icon-discount.png";
import productBannerImg from "../../assets/img/empty-banner-img.png";

// Assets
import NoDataImg from "../../assets/img/icon-warning.png";

import { numberWithCommas } from "./../../utils/number";
// Config Data
import config from "../../config.json";
import NoDataFoundCard from "../common/noData/noDataFound";
import _ from "lodash";

const { googleApiKey, CURRENCY_CODE } = config;

export default function Shop({ title, nearBy = false, code, shopName = "" }) {
  const commonContext = useContext(CommonContext);
  const { languageData } = commonContext;

  const [center, setCenter] = useState(getLocalStorageUserLocation());
  const initialShopValues = {
    page: 1,
    pageSize: 10,
    lat: center.lat,
    lng: center.lng,
    name: shopName,
    code: code,
  };
  const [params, setParams] = useState(initialShopValues);
  const { page } = params;
  const [shops, setShops] = useState([]);
  const [shopCount, setShopCount] = useState(0);
  const [isLoadShops, setIsLoadShops] = useState(true);
  const [isLoad, setIsLoad] = useState(true);
  const userLocation = getLocalStorageUserLocation();

  useEffect(() => {
    setShops([]);
    setShopCount(0);
    setParams({ ...params, page: 1, name: shopName });
  }, [shopName]);

  useEffect(() => {
    loadShops();
  }, [params]);

  const loadShops = async () => {
    try {
      setIsLoadShops(true);
      const config = {
        params: { ...params },
      };

      const { data } = nearBy
        ? await getNearbyShop(config)
        : await getRecommendedShop(config);

      if (data.data) {
        setShopCount(data.dataCount);
        let shops = _.cloneDeep(data.data);
        for (let item of shops) {
          item.isDiscount = false;
          if (item.discount) {
            item.isDiscount = true;
          }
          // For Rating
          const rating = await loadShopRating(item.ratings);
          item.rating = rating.rating;
          item.ratingCount = rating.count;
          // For Delivery
          item.isDeliveryShop = false;
          if (item.deliverySettings.length > 0) {
            item.deliveryFee = 0;
            item.isDeliveryShop = true;
            const currentDistance = await calculateDistance(
              parseFloat(item.lat),
              parseFloat(item.lng),
              userLocation.lat,
              userLocation.lng
            );
            const defaultDeliSetting = await item.deliverySettings.find(
              (s) => s.isDefault
            );
            if (defaultDeliSetting != null) {
              item.deliveryFee += defaultDeliSetting.fees;
            }
            const nextDeliSetting = await item.deliverySettings.find(
              (s) => !s.isDefault && s.distanceFrom <= currentDistance
            );
            if (nextDeliSetting) {
              item.deliveryFee += nextDeliSetting.fees;
            }
          }
        }
        setShops(shops);
      }
      setIsLoadShops(false);
      setIsLoad(false);
    } catch (error) {
      setIsLoadShops(false);
      setIsLoad(false);
      NotificationManager.error("shop failed", languageData.serverError);
    }
  };

  const loadShopRating = (data) => {
    let tRating = 0;
    let rating = { rating: 0, count: 0 };

    let fiveStarCount = 0;
    let fourStarCount = 0;
    let threeStarCount = 0;
    let twoStarCount = 0;
    let oneStarCount = 0;
    if (data.length > 0) {
      data?.map((item) => {
        if (item === 5) {
          fiveStarCount += 1;
        }
        if (item === 4) {
          fourStarCount += 1;
        }
        if (item === 3) {
          threeStarCount += 1;
        }
        if (item === 2) {
          twoStarCount += 1;
        }
        if (item === 1) {
          oneStarCount += 1;
        }
      });
    }

    let oneTotal = oneStarCount * 1;
    let twoTotal = twoStarCount * 2;
    let threeTotal = threeStarCount * 3;
    let fourTotal = fourStarCount * 4;
    let fiveTotal = fiveStarCount * 5;

    let totalClicks =
      oneStarCount +
      twoStarCount +
      threeStarCount +
      fourStarCount +
      fiveStarCount;
    let totalStars = oneTotal + twoTotal + threeTotal + fourTotal + fiveTotal;
    tRating = totalStars / totalClicks || 0;

    rating.rating = tRating;
    if (rating.rating > 0) {
      rating.rating = tRating.toPrecision(2);
    }
    rating.count = data.length;

    return rating;
  };

  const options = {
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    navText: [
      "<div class='nav-button owl-prev'></div>",
      "<div class='nav-button owl-next'></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      991: {
        items: 3,
      },
      1024: {
        items: 4,
      },
    },
  };

  if (shops.length > 0)
    return (
      <section>
        <div className="container">
          {/* <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              
            </div>
          </div> */}
          <div className="row mb-4">
            <div className="col-12 d-flex flex-row justify-content-between align-items-center">
              <h5 className="section-title mb-0">{title}</h5>
              <div className="view-all">
                <Link
                  to="/shopList"
                  state={{ title: title, nearBy: nearBy, code: code }}
                  className="view-all-link"
                >
                  <span>{languageData.viewAll}</span>
                </Link>
              </div>
            </div>
          </div>
          {shops.length > 0 ? (
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 carousel-slide mb-3">
                {/* Web Ui */}
                <div className="d-none d-xl-block d-lg-block d-md-block d-sm-none carousel-wrapper">
                  <OwlCarousel className="shop_wrapper owl-theme" {...options}>
                    {shops.map((item, index) => {
                      return (
                        <Link
                          to={`/productList/${item.route}`}
                          className="shop-link"
                          key={index}
                        >
                          <div className="shop--content">
                            <div className="shop-img-holder">
                              <Image
                                name={item.bannerImage}
                                alt="Shop Image"
                                className="shop-img"
                                banner
                              />
                              <div className="rating-area">
                                <div className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className="star-icon"
                                  />
                                  <span className="rating">
                                    {item.rating} ({item.ratingCount})
                                  </span>
                                </div>
                              </div>

                              {item.isDeliveryShop && (
                                <div className="delifee-area">
                                  <div className="d-flex align-items-center">
                                    <InlineIcon
                                      icon={BicycleIcon}
                                      className="bicycle-icon"
                                    />
                                    <span className="deli-fee">
                                      {item.deliveryFee}{" "}
                                      {languageData[CURRENCY_CODE]}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="shop-name-holder">
                              <div className="shop--name">
                                <span>{item.name}</span>
                              </div>
                              {/* {item.isDiscount && (
                            <p className="promo-info">
                              {item.discount.description}
                            </p>
                          )} */}
                            </div>
                            {item.isDiscount && (
                              <div className="discount-wrap">
                                {/* <img src={IconDiscount} className="discount-img" /> */}
                                <span>{item.discount.title}</span>
                              </div>
                            )}
                          </div>
                        </Link>
                      );
                    })}
                  </OwlCarousel>
                </div>

                {/* Mobile Ui */}
                <div className="d-block d-xl-none d-lg-none d-md-none d-sm-block">
                  <ScrollContainer
                    className="shop_wrapper d-flex flex-row"
                    {...options}
                  >
                    {shops.map((item, index) => {
                      return (
                        <Link
                          to={`/productList/${item.route}`}
                          className="shop-link"
                          key={index}
                        >
                          <div className="shop--content">
                            <div className="shop-img-holder">
                              <Image
                                name={item.bannerImage}
                                alt="Shop Image"
                                className="shop-img"
                                banner
                              />
                              <div className="rating-area">
                                <div className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className="star-icon"
                                  />
                                  <span className="rating">
                                    {item.rating} ({item.ratingCount})
                                  </span>
                                </div>
                              </div>

                              {item.isDeliveryShop && (
                                <div className="delifee-area">
                                  <div className="d-flex align-items-center">
                                    <InlineIcon
                                      icon={BicycleIcon}
                                      className="bicycle-icon"
                                    />
                                    <span className="deli-fee">
                                      {item.deliveryFee}{" "}
                                      {languageData[CURRENCY_CODE]}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="shop-name-holder">
                              <div className="shop--name">
                                <span>{item.name}</span>
                              </div>
                              {/* {item.isDiscount && (
                            <p className="promo-info">
                              {item.discount.description}
                            </p>
                          )} */}
                            </div>
                            {item.isDiscount && (
                              <div className="discount-wrap">
                                {/* <img src={IconDiscount} className="discount-img" /> */}
                                <span>{item.discount.title}</span>
                              </div>
                            )}
                          </div>
                        </Link>
                      );
                    })}
                  </ScrollContainer>
                </div>
              </div>
            </div>
          ) : (
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div>
                  <NoDataFoundCard
                    text={
                      code == "shop"
                        ? languageData.noAnyShops
                        : languageData.noAnyRestaurants
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  return null;
}
